<template>
  <v-container>
    <v-col cols="12">
      <base-material-card color="primary" icon="mdi-account-multiple-plus">
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-form @submit.prevent="create">
            <v-row>
              <v-col cols="12">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-text-field
                    v-model="taskName" :label="$t('task-page.task-name')" clearable/>
                  <span class="red--text font-weight-light">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" lg="6">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-datetime-picker v-model="startTime" :label="$t('task-page.start-time')"
                                     :timePickerProps="timePickerProps"
                                     :date-picker-props="datePickerProps" :text-field-props="textFieldProps">
                  </v-datetime-picker>
                  <span class="red--text font-weight-light">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6" lg="6">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-datetime-picker v-model="dueTime" :label="$t('task-page.due-time')"
                                     :timePickerProps="timePickerProps"
                                     :date-picker-props="datePickerProps" :text-field-props="textFieldProps">
                  </v-datetime-picker>
                  <span class="red--text font-weight-light">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>
              <v-alert v-if="dueTime!=='' && startTime>dueTime"
                       dense outlined border="left" text type="error">
                {{$t('task-page.due-time-validation')}}
              </v-alert>
            </v-row>

            <v-row>
              <v-col sm="12" lg="12">
                <v-text-field
                  v-model="description" :label="$t('task-page.description')" clearable/>
              </v-col>
            </v-row>

            <v-btn color="primary" @click="create" :disabled="invalid" block>
              {{$t('buttons.create-task')}}
            </v-btn>
          </v-form>
        </validation-observer>
      </base-material-card>
    </v-col>
  </v-container>
</template>

<script>
  import {AXIOS} from "../../../http-commons";
  import {ValidationObserver, ValidationProvider, extend} from 'vee-validate';
  import {required} from 'vee-validate/dist/rules';

  extend('required', {
    ...required,
    message: 'The field is required'
  });

  let moment = require('moment');

  export default {
    name: "Create Task",
    components: {ValidationProvider, ValidationObserver},
    data() {
      return {
        loading: false,
        taskName: '',
        description: '',
        timePickerProps: {
          format: "24hr"
        },
        datePickerProps: {
          firstDayOfWeek: 1
        },
        textFieldProps: {
          prependIcon: 'mdi-clock-time-four-outline'
        },
        dueTime: new Date(),
        startTime: new Date()
      }
    },
    methods: {
      succNotification(response) {
        this.$message({
          message: 'Task <strong>' + response.data.taskName +
            '</strong> was saved successfully',
          type: 'success',
          dangerouslyUseHTMLString: true,
          duration: 3000
        });
      },
      create() {
        this.loading = true;
        let format = "yyyy-MM-DDTHH:mm:ss";
        let startTime = moment(this.startTime).format(format);
        let dueTime = moment(this.dueTime).format(format);
        let params = new URLSearchParams();
        params.append('taskName', this.taskName);
        params.append('description', this.description);
        params.append('dueTime', dueTime);
        params.append('startTime', startTime);
        params.append('userId', this.currentUser.id);
        AXIOS.post(`/api/createTask/`, params)
          .then(response => {
            this.response = response.data;
            this.succNotification(response);
            this.$router.push({name: 'Task Card', params: {taskId: response.data.taskId}})
            this.loading = false;
          })
      },
    },
    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      }
    },
  }
</script>

<style scoped>
  .red--text {
    font-size: 12px;
  }
</style>
