var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{attrs:{"color":"primary","icon":"mdi-account-multiple-plus"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.create($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('task-page.task-name'),"clearable":""},model:{value:(_vm.taskName),callback:function ($$v) {_vm.taskName=$$v},expression:"taskName"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-datetime-picker',{attrs:{"label":_vm.$t('task-page.start-time'),"timePickerProps":_vm.timePickerProps,"date-picker-props":_vm.datePickerProps,"text-field-props":_vm.textFieldProps},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-datetime-picker',{attrs:{"label":_vm.$t('task-page.due-time'),"timePickerProps":_vm.timePickerProps,"date-picker-props":_vm.datePickerProps,"text-field-props":_vm.textFieldProps},model:{value:(_vm.dueTime),callback:function ($$v) {_vm.dueTime=$$v},expression:"dueTime"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.dueTime!=='' && _vm.startTime>_vm.dueTime)?_c('v-alert',{attrs:{"dense":"","outlined":"","border":"left","text":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.$t('task-page.due-time-validation'))+" ")]):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12","lg":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('task-page.description'),"clearable":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('v-btn',{attrs:{"color":"primary","disabled":invalid,"block":""},on:{"click":_vm.create}},[_vm._v(" "+_vm._s(_vm.$t('buttons.create-task'))+" ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }